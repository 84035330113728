import pescado from "../../../static/assets/images/menu/especiales/MOJARRA.jpg"
import pescado_desktop from "../../../static/assets/images/menu/especiales/desktop/MOJARRA.jpg"
import arroz from "../../../static/assets/images/menu/especiales/ARROZ_CON_POLLO.jpg"
import arroz_desktop from "../../../static/assets/images/menu/especiales/desktop/ARROZ_CON_POLLO.jpg"

const especialesData = [
  {
    imageSrc: pescado,
    imageDesktopSrc: pescado_desktop,
    imageDescription: "Mojarra frita con arroz papa y ensalada",
  },
  {
    imageSrc: arroz,
    imageDesktopSrc: arroz_desktop,
    imageDescription: "Arroz con pollo con papa y ensalada",
  },
]

export default especialesData
